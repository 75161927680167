@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    font-family: 'Raleway', sans-serif !important;
    font-variant-numeric: lining-nums !important;

}

h2 {
    font-weight: bold !important;
    font-size: 18px;
    line-height: 16px;
    letter-spacing: 0.05em;

}

h3 {
    font-weight: bold !important;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.05em;
}

h4 {
    font-weight: bold !important;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.05em;
}

body {
    margin: 0 !important;
    padding: 0;
}

/* homepage */
.sliderPhoto {
    max-height: 100vh;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;

}

.sliderInside {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 22px 30px;
    color: white;
    background: linear-gradient(0deg, #000000 0%, rgba(28, 28, 28, 0) 50%);

}

.sliderMiddle {
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.sliderMiddleItems {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sliderDownItems {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
}

.sliderDownItem2 {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    padding-top: 10px;

    color: #FFFFFF;
}

.sliderMiddleItem1 {
    font-weight: bold;
    font-size: 42px;
    text-align: center;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.sliderMiddleItem2 {
    margin-top: 10px !important;
    font-weight: normal;
    font-size: 32px;
    line-height: 38px;

    text-align: center;
    letter-spacing: 0.25em;
    text-transform: uppercase;

    color: #FFFFFF;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.sliderMiddleItem3 {
    margin-top: 20px !important;
}

.arama-root {
    display: flex;
    background-color: white;
    padding: 4% 7%;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
}

.arama-top {
    display: flex;
    flex-direction: column;
}

/* .arama-middle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 70px;
} */

.arama-middle-items {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
}

.arama-middle-items-last {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-end;
}

.arama-bottom {
    display: flex;
    flex-direction: column;
}

.arama-top-item1 {
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;

    color: #2C3E50;
}

.arama-top-item2 {
    font-weight: 300;
    font-size: 36px;
    line-height: 42px;

    color: #2C3E50;
}

.arama-top-item3 {
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 30px;

    color: #2C3E50;
}

.arama-middle-item1 {
    font-size: 16px;
    line-height: 19px;
    padding: 15px;

    color: #2C3E50;
}

.arama-middle-item2 {
    background: #FFFFFF;
    border: 1px solid #2C3E50;
    box-sizing: border-box;
    border-radius: 4px;
    height: 50px;
    align-items: center !important;
    display: flex !important;
    margin-top: 10px !important;
}

.arama-middle-items .ant-input {
    border-radius: 5px !important;
    border: 1px solid black;
    box-shadow: none !important;
    border-color: #000000 !important;
}

.arama-middle-item3 {
    background: #2C3E50;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin-top: 10px !important;
}

.arama-middle-item3-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    color: #FFFFFF !important;
}

.arama-bottom-top {
    align-items: center;
    padding-bottom: 25px;
}

.arama-bottom-divider1 {
    border: 1px solid rgba(0, 0, 0, 0.25);
    height: 1px;
}

.arama-bottom-divider2 {
    border: 1px solid rgba(0, 0, 0, 0.25);
    height: 1px;
}

.arama-bottom-text1 {
    justify-content: center;
    display: flex;
    text-align: center;
    min-width: 160px !important;
    padding: 0 10px 0 10px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.5);
}

.arama-bottom-bottom-item1 {
    padding-left: 25px;
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    height: 62px;
    align-items: center !important;

    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    color: #FFFFFF;

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.sticky-select .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    color: #FFFFFF !important;
    text-shadow: 0px 0px 2px #2C3E50;
}

.ant-select-selector {
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
    color: grey !important;
}

.sticky-select {
    display: flex;
    align-items: center;
}

.card-root {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 550px;
    width: 100%;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
    border-radius: 24px;
    overflow: hidden;

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;

}

.last-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F4F4F4;
    padding: 4% 7% 0 7%;

}

.last-view-top {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    max-width: 1600px;
    padding-bottom: 55px;
}

.last-view-root-2 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: none !important;

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
}

.last-view-root {
    display: flex;
    justify-content: center;
    height: 20vh;
    width: 100%;
    max-width: 1600px;
    padding: 0 5% 0 5%;

}

.last-view-root-az-2 {
    display: flex;
    justify-content: center;
    height: 20vh;
    width: 100%;
    max-width: 750px;
    padding: 0 5% 0 5%;

}

.last-view-root-az-3 {
    display: flex;
    justify-content: center;
    height: 20vh;
    width: 100%;
    max-width: 1000px;
    padding: 0 5% 0 5%;

}

.last-view-root .swiper-slide {
    display: flex;
    justify-content: center;
}

.last-view-root-az .swiper-slide {
    display: flex;
    justify-content: center;
}

.card-top {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 165px;
    height: 40px;
    margin-top: 50px;
    background: #F4F4F4;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 0px 4px 4px 0px;

}

.card-small-top {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 165px;
    height: 40px;
    margin-top: 20px;
    background: #F4F4F4;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 0px 4px 4px 0px;

}

.card-overlay {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #1C1C1C 0%, rgba(28, 28, 28, 0) 50%);

}

.last-view-overlay {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #1C1C1C 0%, rgba(28, 28, 28, 0) 50%);
}

.card-bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100px;
    margin: 20px;

}



.card-overlay:hover .card-bottom-bottom {
    margin-bottom: -15px;
}

.card-bottom-top {
    padding-bottom: 15px;

}

.card-bottom-bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
    padding: 15px 10px;
    transition: all 0.5s !important;
    margin-bottom: -80px;
}

.card-top-item1 {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #2C3E50;

}

.card-bottom-top-item1 {
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
    color: #FFFFFF !important;

}

.card-small-text {
    font-weight: 600;
    font-size: 26px;
    color: #FFFFFF !important;

}

.card-bottom-bottom-item1 {
    font-size: 14px;
    line-height: 16px;
    font-feature-settings: 'pnum'on, 'lnum'on;
    color: #FFFFFF !important;

}

.kesfet-root {
    display: flex;
    flex-direction: column;
    background-color: #F4F4F4;
    padding: 4% 7%
}

.kesfet-top {
    display: flex;
    flex-direction: column;
    padding-bottom: 55px;
}

.kesfet-middle {
    display: flex !important;
    align-items: center !important;
    margin-bottom: 20px;
}


.kesfet-bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 35px;
}

.kesfet-button {
    border: 1px solid #2C3E50 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    background-color: #F4F4F4 !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    display: flex !important;
    align-items: center !important;
    text-align: center !important;
    letter-spacing: 0.1em !important;
    text-transform: uppercase !important;
    color: #2C3E50 !important;
    flex: none !important;
    order: 0 !important;
    flex-grow: 0 !important;
    margin: 0px 10px !important;
    padding: 20px 30px !important;

}

.kesfet-baslik {
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    color: #2C3E50;
    text-transform: uppercase;
}

.kesfet-text {
    font-size: 16px;
    line-height: 19px;
    color: #2C3E50;
}

.info-root {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
}

.info-root-top {
    display: flex;
    width: 100%;
    flex-direction: row;
    background-color: #FFFFFF !important;
    padding: 2% 5%;
    z-index: 1000;
}

.info-img {
    width: 100%;
    height: auto;
    max-height: 50px;
}

.info-root-middle {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #2C3E50;
    padding: 2% 5%;
}

.info-root-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
}

.info-img {
    width: 100%;
}

.info-img2 {
    width: 20%;
    cursor: pointer;
}

.info-middle1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 120px;
}

.info-middle2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 120px;
}

.info-middle1-top1 {
    font-weight: 200;
    font-size: 30px;
    line-height: 38px;
    color: #FFFFFF !important;
}

.info-middle1-top2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: #FFFFFF !important;
}

.info-middle1-middle {
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF !important;
    cursor: pointer;
}

.info-middle1-middle-geçici {
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF !important;
    cursor: pointer;
    padding-bottom: 70px;
}

.info-middle1-middle-baslik {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF !important;
}

.info-middle1-item2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    max-width: 180px;
}

.info-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    font-feature-settings: 'pnum'on, 'lnum'on;
    color: #2C3E50;
    padding: 6px;
}

.closeicon {
    margin-left: 50px;
    color: white !important;
    font-size: 1.8rem;
}

/* login */
.login-root {
    display: flex;
    flex-direction: row;
    height: 100vh;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.login-root-left {
    width: 70%;
    height: 100%;
    background-color: red;
    background: linear-gradient(270deg, #FFFFFF 0%, rgba(28, 28, 28, 0) 50%);
    padding: 30px;
}

.login-root-left1 {
    font-weight: 200;
    font-size: 40px;
    line-height: 31px;
    color: #FFFFFF !important;
}

.login-root-left2 {
    font-weight: 500;
    font-size: 40px;
    line-height: 31px;
    color: #FFFFFF !important;
}

.login-root-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background: linear-gradient(270deg, #FFFFFF 25%, rgba(28, 28, 28, 0) 60%);
}

.login-root-right1 {
    display: flex;
    justify-content: flex-end;

}

.login-root-right2 {
    display: flex !important;
    flex-direction: column !important;
    max-width: 273px;
    margin-right: 5%;
}

.register-root-right2 {
    display: flex !important;
    flex-direction: column !important;
    max-width: 273px;
    margin-right: 5%;
}

.login-right-top1 {
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;

    color: #000000;
}

.login-right-top2 {
    font-weight: 400;
    font-size: 48px;
    line-height: 56px;

    color: #000000;
}

.login-right-middle1 {
    background: transparent !important;
    border: none !important;
    margin: 8px 0px;
    box-shadow: none !important;
}

.login-right-middle2 {
    border: 1px solid black !important;
    border-radius: 10px !important;
    margin: 8px 0px;
    box-shadow: none !important;
}

.login-right-middle3 {
    font-size: 14px;
    line-height: 16px;
    color: rgba(44, 62, 80, 0.5);
    margin: 5px 5px;
    border: none !important;
    border-bottom: 1px solid rgba(44, 62, 80, 0.2) !important;
    box-shadow: none !important;
}

.login-button-blue {
    background-color: #2C3E50 !important;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25) !important;
    border-radius: 4px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    letter-spacing: 0.1em !important;
    color: #FFFFFF !important;
    padding: 12px !important;
    width: 100% !important;
    height: auto !important;
    border-color: #2C3E50 !important;
    text-transform: uppercase !important;
}

.login-button-white {
    background-color: #FFFFFF !important;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25) !important;
    border-radius: 4px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    letter-spacing: 0.1em !important;
    color: #2C3E50 !important;
    padding: 12px !important;
    width: 100%;
    height: auto !important;
    border-color: #2C3E50 !important;
    text-transform: uppercase !important;
}

.login-button-white-borderless {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    letter-spacing: 0.1em !important;
    color: #2C3E50 !important;
    border: none !important;
    width: 100%;
    text-transform: uppercase !important;
    background-color: transparent !important;
    box-shadow: none !important;
}

.login-button-transparent-white {
    background-color: transparent !important;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25) !important;
    border-radius: 4px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    letter-spacing: 0.1em !important;
    color: #FFFFFF !important;
    padding: 12px !important;
    width: 100%;
    height: auto !important;
    border-color: #FFFFFF !important;
    text-transform: uppercase !important;
}

.login-button-transparent-blue {
    background-color: transparent !important;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25) !important;
    border-radius: 4px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    letter-spacing: 0.1em !important;
    color: #2C3E50 !important;
    padding: 12px !important;
    width: 100%;
    height: auto !important;
    border-color: #2C3E50 !important;
    text-transform: uppercase !important;
}

.login-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100% !important;
    padding-bottom: 10px;
}

.login-buttons-payment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100% !important;
}

.login-default-text {
    padding: 5px;
    font-size: 14px;
    line-height: 16px;
    color: rgba(44, 62, 80, 0.5);
}

.login-default-text2 {
    padding: 5px;
    font-size: 14px;
    line-height: 16px;
    color: rgba(44, 62, 80, 0.5);
    cursor: pointer;
}

.ant-input {
    background-color: transparent !important;
}

.ant-input-lg {
    background-color: transparent !important;
}

.register-root-left1 {
    font-weight: 200;
    font-size: 40px;
    line-height: 31px;
    color: #2C3E50 !important;
}

.register-root-left2 {
    font-weight: 500;
    font-size: 40px;
    line-height: 31px;
    color: #2C3E50 !important;
}

.rezervation-root {
    align-items: center;
    padding: 25px;
    margin-top: 60px;
    max-width: 400px;
    min-width: 290px;
    height: auto;
    background: #2C3E50;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    margin-left: 0 !important;
}

.rezervation-top {
    display: flex;
    flex-direction: column;
    padding: 20px 20px !important;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 25px;
}

.rezervation-top-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 4px;
}

.rezervation-top-bottom-bottom {
    padding-top: 10px;
    margin-top: 5px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.rezervation-top-text1 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #FFFFFF !important;
}

.rezervation-top-text2 {
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    font-feature-settings: 'pnum'on, 'lnum'on;
    color: #FFFFFF !important;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.rezervation-bottom {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 20px !important;
    background: #FFFFFF !important;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
}

.rezervation-bottom-isdisable {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 20px !important;
    background: #FFFFFF !important;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    cursor: pointer;
}

.rezervation-bottom-items {
    display: flex !important;
    align-items: center;
    width: 100% !important;
    background-color: #FFFFFF;
}

.rezervation-bottom-item1 {
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #2C3E50;
}

.rezervation-bottom-item2 {
    width: 100% !important;
    border-top: 2px solid rgba(44, 62, 80, 0.1);
    padding-top: 20px;
}

.rezervation-bottom-item2-1 {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
}

.rezervation-sayac-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    font-feature-settings: 'pnum'on, 'lnum'on;
    color: rgba(44, 62, 80, 0.5);
    margin: 0px 5px;
}

.rezervation-bottom-item3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 15px;
    background: rgba(44, 62, 80, 0.05);
    border: 1px dashed rgba(44, 62, 80, 0.25);
    box-sizing: border-box;
    border-radius: 25px;
}

.rezervation-bottom-item3-top {
    padding-bottom: 10px;
}

.rezervation-bottom-item3-bottom {
    border-top: 1px solid rgba(44, 62, 80, 0.1);
    justify-content: flex-end;
    display: flex;
    padding-top: 10px;
}

.rezervation-button-item3-text {
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 14px !important;
    display: flex !important;
    align-items: center !important;
    letter-spacing: 0.1em !important;
    text-transform: uppercase !important;
    color: rgba(44, 62, 80, 0.5) !important;
}

.rezervation-button-item3-text2 {
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    color: rgba(44, 62, 80, 0.5);
}

.search-root {
    height: 35vh;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
}

.search-root-1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background-color: red;
    background: linear-gradient(0deg, #FFFFFF 0%, rgba(28, 28, 28, 0) 50%);
    padding: 30px;
}

.search-root-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: -150px;
    width: 87%;
    padding: 4%;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    z-index: 1001;
    margin-bottom: 20px;
}

.search-top {
    width: 100%;
    border-bottom: 2px solid rgba(44, 62, 80, 0.1);
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.search-middle {
    display: flex !important;
    width: 100%;
    align-items: center !important;
}

.search-text {
    font-size: 28px;
    line-height: 42px;
    color: #2C3E50;
}

.search-bottom {}

.search-bottom-button {
    background-color: transparent !important;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25) !important;
    border-radius: 4px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    letter-spacing: 0.1em !important;
    color: #2C3E50 !important;
    padding: 12px !important;
    height: auto !important;
    border-color: #2C3E50 !important;
    text-transform: uppercase !important;
    margin-left: 10px;
}

.search-bottom-text1 {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #2C3E50;
    margin: 0px !important;
}

.swiper-pagination-bullet {
    background-color: #FFFFFF !important;
    width: 15px !important;
    height: 15px !important;
}

.swiper-button-prev {
    position: absolute !important;
    width: 50px !important;
    height: 50px !important;
    left: 5vw !important;
    top: 50% !important;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 25px;
}

.swiper-button-next {
    position: absolute !important;
    width: 50px !important;
    height: 50px !important;
    right: 5vw !important;
    top: 50% !important;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 25px;
}

.detail-root {
    width: 100%;
}

.detail-root-2 {
    position: relative;
    margin-top: -150px;
    max-width: 1500px;
    min-height: 415px;
    width: 87%;
    padding: 4%;
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    margin-bottom: 20px;
}

.detail-left {
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;
}

.detail-right {
    margin-left: 20px;
}

.detail-left-middle {
    display: flex;
    flex-direction: column;
}

.detail-left-middle-items {
    display: flex;
    height: auto !important;
    max-width: 100% !important;
}

.detail-baslik {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1em;
    padding-bottom: 10px;
    color: #000000;

}

.detail-text {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.1em;
    padding-bottom: 10px;
    color: #000000;
}

.detail-bottom-text1 {
    justify-content: center;
    display: flex;
    text-align: center !important;
    font-size: 28px;
    line-height: 33px;
    letter-spacing: 0.1em;
    color: #2C3E50;
    padding: 0 10px 0 10px;
}

.detail-bottom-top {
    align-items: center;
    padding-bottom: 25px;
    padding-top: 25px;
}

.detail-bottom-divider1 {
    border: 1px solid rgba(0, 0, 0, 0.25);
    width: 10%;
    max-width: 75px;
}

.detail-bottom-divider2 {
    border: 1px solid rgba(0, 0, 0, 0.25);
    width: 100%;
}

.detail-bottom-bottom-item1 {
    padding-left: 25px;
    display: flex;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    height: 125px;
    align-items: center !important;

    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    color: #FFFFFF;

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.sticky-top {
    width: 100%;
    position: fixed;
    background-color: red;
    z-index: 1020;
    height: 0
}

.sticky-top-transparent {
    display: flex;
    background-color: #2C3E50;
    height: 100px;
    padding: 15px 40px;
    flex-direction: row;
    justify-content: space-between;
    transition: all 0.5s;

}

.sticky-top-no-transparent {
    display: flex;
    background-color: transparent;
    height: 100px;
    padding: 15px 40px;
    flex-direction: row;
    justify-content: space-between;
    transition: all 0.5s;

}

.sticky-top-text-blue {
    color: #2C3E50 !important;
    fill: #2C3E50 !important;
    font-size: 2.5rem;
}

.sticky-top-text-white {
    color: #FFFFFF !important;
    fill: #FFFFFF !important;
    font-size: 2.5rem;
    filter: drop-shadow(0px 0px 1px #2C3E50);
}

.ant-select-arrow {
    color: #FFFFFF !important;
    filter: drop-shadow(0px 0px 1px #2C3E50);
}

.whatsapp_float {
    position: fixed;
    width: 60px;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 6px 9px 20px #999;
    z-index: 1010;
}

.whatsapp-icon {
    margin-top: 16px;
}

.rez-detail-root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 4%;
    background: #F4F4F4;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
}

.rez-detail-top {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #2C3E50;
    width: 100%;
    padding: 15px 0px;
    border-bottom: 2px solid rgba(44, 62, 80, 0.1);
}

.rez-detail-middle-text1 {
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-indent: 20px;
    color: rgba(44, 62, 80, 0.5) !important;
    margin-top: 15px;
}

.rez-detail-middle-text3 {
    font-size: 18px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-indent: 20px;
    color: rgba(44, 62, 80, 0.5) !important;
    margin-top: 15px;
}

.rez-detail-middle-text2 input::placeholder {
    color: rgb(83, 83, 88);
    font-weight: 500;
}

.rez-detail-middle-text2 .date-picker-style {
    color: rgb(83, 83, 88);
    font-weight: 500;
}

.rez-detail-middle {
    display: flex;
    justify-content: center;
    width: 100%;
    background: #FFFFFF;
    border-radius: 15px;
    padding: 4%;
    margin-top: 15px;
}

.rez-detail-middle-item1 {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.rez-detail-middle-text2 {
    font-size: 14px;
    line-height: 16px;
    color: rgba(44, 62, 80, 0.5);
    margin: 0px 5px;
    border: none !important;
    border-bottom: 1px solid rgba(44, 62, 80, 0.2) !important;
    box-shadow: none !important;
}

.ant-tabs-tab-btn {
    color: #000000 !important;
}

.ant-tabs-ink-bar-animated {
    background-color: #000000 !important;
}

.tab1 {
    display: flex;
    width: 100% !important;
    flex-direction: row;
    padding: 21px 25px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.tab1-baslik {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #2C3E50;
    margin: 10px 0px;
}

.tab1-baslik-s {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #2C3E50;
    margin: 10px 0px;
    opacity: 0.5;
}

.tab1-text {
    font-size: 16px;
    line-height: 19px;
    font-feature-settings: 'pnum'on, 'lnum'on;
    color: #2C3E50;
    margin: 10px 0px;
}

.tab1-root {
    display: flex !important;
    padding: 10px;
}

.tab1-hesap-root {
    padding: 10px !important;
}

.tab1-hesap {
    display: flex;
    flex-direction: column;
}

.tab1-hesap-baslik {
    font-size: 14px;
    line-height: 14px;
    color: rgba(44, 62, 80, 0.5) !important;
    margin-bottom: 5px;
}

.tab1-hesap-text {
    font-size: 16px;
    line-height: 16px;
    font-feature-settings: 'pnum'on, 'lnum'on;
    color: #2C3E50;
}

.tab1-hesap-text1 {
    font-size: 16px;
    line-height: 16px;
    font-feature-settings: 'pnum'on, 'lnum'on;
    color: #2C3E50;
    text-transform: capitalize;
}

.account-baslik2 {
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    color: #2C3E50;

}

.account-baslik {
    font-weight: bold;
    font-size: 24px;
    line-height: 42px;
    color: #2C3E50;
    text-transform: capitalize;
    padding-left: 15px;
}

.country-input {
    display: flex;
    align-items: center !important;
    justify-content: center;
    margin-bottom: 5px;
    margin-top: 5px;
}

.register-email-modal-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ant-modal-content {
    overflow: hidden;
    border-radius: 10px !important;
}

.verification-modal .ant-modal-content {
    width: 400px !important;
}

.verification-modal {
    justify-content: center;
}

.reset-modal .ant-modal-content {
    width: 400px !important;
}

.reset-modal {
    justify-content: center;
}

.payment-modal {
    justify-content: center;
}

.payment-modal .ant-modal-content {
    width: 90% !important;
    max-width: 500px !important;
}

.payment-modal .card-container {
    width: 100% !important;
}

.ant-modal-confirm .ant-modal-content {
    max-width: 400px;
}

.ant-modal-confirm {
    display: flex;
    justify-content: center;
}

.logout-user {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding-right: 11px !important;
}

.logout-user .ant-btn-primary {
    box-shadow: none !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.verification-page-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;

}

.cart-root {
    display: flex !important;
    flex-direction: row !important;
    min-height: 300px;
}

.cart-item-left {
    display: flex !important;
    flex-direction: column;
    align-items: center !important;
    padding: 10px;
    width: 100% !important;
}

.cart-item-right {

    padding: 10px 10px !important;
    max-width: 400px !important;
    width: 100% !important;
    border: 1px dashed grey !important;
    border-radius: 20px;
    background-color: lightgrey;
    height: 300px;
}

.cart-items {
    display: flex;
    flex-direction: row;
    width: 100% !important;
    padding: 21px 25px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin-bottom: 20px;
}

.cart-items-column {
    display: flex;
    flex-direction: row;
    width: 100% !important;
    height: 100%;
    padding: 15px 10px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

.cart-inside {
    display: flex !important;
    justify-content: center !important;
}

.cart-item1 {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
}

.cart-ortala {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    min-height: 70px !important;
}

.cart-text1 {
    font-size: 16px;
    line-height: 19px;
    font-feature-settings: 'pnum'on, 'lnum'on;
    color: #2C3E50;
    margin: 2px 0px;
}

.cart-text2 {
    font-size: 34px;
    padding-left: 20px;
    line-height: 19px;
    font-feature-settings: 'pnum'on, 'lnum'on;
    color: #2C3E50;
    margin: 2px 0px;
}

.cart-text3 {
    font-size: 12px;
    line-height: 19px;
    font-feature-settings: 'pnum'on, 'lnum'on;
    color: #2C3E50;
    padding: 10px 0;
    padding-left: 5px;
}

.cart-items2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-height: 200px;
}

.rez-detail-date {
    width: 100%;
    margin-top: 15px;
}

.cart-icon {
    width: 30px;
    height: 30px;
}

.cart-icon2 {
    width: 25px;
    height: 25px;
}

.shopping-cart-root {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 20px 20px;
    margin-bottom: 20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.order-cart-root {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 20px 20px;
    margin-bottom: 10px !important;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    cursor: pointer;
}

.shopping-cart-right {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 30px;

}

.order-cart-right {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 0px;

}

.shopping-cart-top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 10px;
}

.shopping-cart-bottom {
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 10px;
}

.shopping-cart-text1 {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #2C3E50;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.shopping-cart-text2 {
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #2C3E50;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.shopping-cart-text3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: rgba(44, 62, 80, 0.5);
    flex: none;
    order: 0;
    flex-grow: 0;
}

.shopping-cart-bottom-inside {
    display: flex;
    flex-direction: row;

}

.shopping-cart-bottom-outside {
    display: flex !important;
    align-items: center !important;
    width: 100%;
}

.navbar-inside {
    color: #FFFFFF !important;
    padding: 15px !important;
    cursor: pointer !important;
    text-transform: uppercase;
}

.navbar-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 20px;
}

.slider-root {
    display: flex;
    height: 50vh;
    width: 100%;
}



.ant-picker-dropdown {
    z-index: 1019 !important;
}

.my-orders {
    width: 100%;
}

.my-orders-tab {
    margin-bottom: 0px !important;
}

.cart-svg svg {
    filter: drop-shadow(0px 0px 1px #2C3E50);
}

.shopping-cart-payed {
    display: flex;
    align-items: center;
    padding: 2px 10px;
    border-radius: 10px;
    background-color: rgba(22, 160, 133, 0.1);
    color: rgba(22, 160, 133, 1);
    text-align: center;
}

.shopping-cart-reserved {
    display: flex;
    align-items: center;
    padding: 2px 10px;
    border-radius: 10px;
    background-color: rgba(52, 152, 219, 0.1);
    color: rgba(52, 152, 219, 1);
    text-align: center;
}

.shopping-cart-waiting {
    display: flex;
    align-items: center;
    padding: 2px 10px;
    border-radius: 10px;
    background-color: rgba(241, 196, 15, 0.1);
    color: rgba(241, 196, 15, 1);
    text-align: center;
}

.shopping-cart-failed {
    display: flex;
    align-items: center;
    padding: 2px 10px;
    border-radius: 10px;
    background-color: rgba(180, 57, 43, 0.1);
    color: rgba(180, 57, 43, 1);
    text-align: center;
}

.cart-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    background-color: rgba(44, 62, 80, 0.2);
}

.cart-empty-text {
    font-size: 18px;
}

.order-baslik {
    font-size: 16px;
    line-height: 16px;
    font-feature-settings: 'pnum'on, 'lnum'on;
    color: #2C3E50;
    font-weight: 300;
    padding-right: 10px;
}

.order-text {
    font-size: 16px;
    line-height: 16px;
    font-feature-settings: 'pnum'on, 'lnum'on;
    color: #2C3E50;
    font-weight: bold;

}

.order-text2 {
    font-size: 16px;
    line-height: 16px;
    font-feature-settings: 'pnum'on, 'lnum'on;
    color: #2C3E50;
    font-weight: bold;
    text-transform: capitalize;
}

.loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.loading-image {
    width: 250px;
    height: 250px;
    border-radius: 20px;
}

.side-nav-top {
    display: flex;
    flex-direction: row;
    background-color: #FFFFFF;
    padding: 10px 0 !important;
    margin: 0 0 10px 0 !important;
    border-radius: 5px;
}

.card-bottom-bottom-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.card-bottom-bottom-right-text {
    font-size: 12px;
    line-height: 16px;
    font-feature-settings: 'pnum'on, 'lnum'on;
    color: #FFFFFF !important;
}

.rez-detail-root .ant-picker {
    width: 100%;
    border-radius: 12px;
}

.order-detail {
    border-radius: 5px;
    background-color: #F5F5F5;
    padding: 10px;
    margin-top: 10px;

}

.order-detail-baslik {}

.order-detail-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: rgba(44, 62, 80, 0.5);
    flex: none;
    order: 0;
    flex-grow: 0;
}

.flight-rezervation {
    border: none !important;
    width: 400px !important;
    height: 300px !important;
}

.asd {
    filter: drop-shadow(rgb(44, 62, 80) 0px 0px 1px);
}

.logo-navbar {
    width: 175px;
    filter: drop-shadow(rgb(0, 0, 0) 0px 0px 1px);
}

.logo-navbar-admin2 {
    width: 75px;
    filter: drop-shadow(rgb(0, 0, 0) 0px 0px 1px);
}

/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/* for mobile */


@media screen and (max-width: 767px) {
    .logout-user {
        padding-right: 5px !important;
    }


    .last-view-root {
        display: flex;
        justify-content: center;
        height: 20vh !important;

    }

    .last-view-root-2 {
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        border-radius: 10px;
        height: 20vh;
        overflow: hidden;
        box-shadow: none !important;

        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        cursor: pointer;
    }

    .card-small-top {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
        height: 40px;
        margin-top: 20px;
        background: #F4F4F4;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
        border-radius: 0px 4px 4px 0px;

    }

    .card-small-text {
        font-weight: 600;
        font-size: 16px !important;
        color: #FFFFFF !important;

    }

    .last-view-root {
        display: flex;
        justify-content: center;
        height: 30vh;
        width: 100%;
        padding: 0;

    }

    .verification-modal .ant-modal-content {
        width: 300px !important;
    }

    .reset-modal .ant-modal-content {
        width: 300px !important;
    }

    .payment-modal .ant-modal-content {
        width: 90% !important;
        max-width: 500px !important;
    }

    .ant-modal-confirm .ant-modal-content {
        max-width: 300px;
    }

    .logo-navbar {
        width: 100px;
        filter: drop-shadow(rgb(0, 0, 0) 0px 0px 1px);
    }

    .flight-rezervation {
        border: none !important;
        width: 300px !important;
        height: 345px !important;
    }

    .my-orders-tab {
        margin-bottom: 20px !important;
    }

    .search-root-2 {
        width: 100%;
        border-radius: 20px;
    }

    .rez-detail-top {
        font-weight: bold;
        font-size: 20px;
        line-height: 23px;
        display: flex;
        align-items: center;
        letter-spacing: 0em;
        text-transform: uppercase;
        color: #2C3E50;
        width: 100%;
        padding: 15px 0px;
        border-bottom: 2px solid rgba(44, 62, 80, 0.1);
    }

    .tab1-root .ant-collapse-content-box {
        padding: 0 5px !important;
    }

    .rez-detail-date {
        width: 100%;
        margin-top: 15px;
        padding-top: 0px;
    }

    .card-bottom-bottom {
        display: flex !important;
        border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
        padding: 15px 10px;
        margin-bottom: -15px;
    }

    .tab1-root .ant-collapse-header {
        padding: 5px 2px !important;
    }

    .kesfet-bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 0px;
    }

    .kesfet-top {
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
    }

    .last-view-top {
        padding-bottom: 20px;
    }

    .whatsapp-icon {
        margin-top: 10px;
    }

    .arama-top-item1 {
        font-weight: bold;
        font-size: 28px;
        line-height: 42px;

        color: #2C3E50;
    }

    .arama-top-item2 {
        font-weight: 300;
        font-size: 28px;
        line-height: 42px;

        color: #2C3E50;
    }

    .info-middle2 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 30px;
    }

    .shopping-cart-root {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 10px 10px;
        margin-bottom: 20px;
        background: #FFFFFF;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }

    .order-cart-root {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 10px 10px;
        margin-bottom: 0px;
        background: #FFFFFF;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
    }

    .shopping-cart-text1 {
        font-weight: bold;
        font-size: 14px;
        line-height: 23px;
        display: flex;
        align-items: center;
        letter-spacing: normal;
        text-transform: uppercase;
        color: #2C3E50;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .shopping-cart-text2 {
        font-size: 16px;
        line-height: 23px;
        display: flex;
        align-items: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #2C3E50;
        flex: none;
        order: 0;
        flex-grow: 0;
    }

    .shopping-cart-right {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-left: 30px;

    }

    .slider-root {
        height: 40vh;
    }

    .arama-top-item3 {
        font-size: 16px;
        line-height: 19px;
        padding-bottom: 10px;

        color: #2C3E50;
    }

    .swiper-button-prev {
        position: absolute !important;
        width: 35px !important;
        height: 35px !important;
        left: 5vw !important;
        top: 50% !important;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 25px;
    }

    .swiper-button-next {
        position: absolute !important;
        width: 35px !important;
        height: 35px !important;
        right: 5vw !important;
        top: 50% !important;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 25px;
    }

    .navbar-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        padding-bottom: 0px;
    }

    .navbar-inside {
        color: #FFFFFF !important;
        padding: 10px 15px !important;
        cursor: pointer !important;
        text-transform: uppercase;
    }

    .detail-root-2 {
        position: relative;
        margin-top: -150px;
        max-width: 1500px;
        width: 100%;
        padding: 4%;
        background: #FFFFFF;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        margin-bottom: 20px;
    }

    .cart-root {
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        min-height: 300px;
    }

    .tab1-baslik {
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        color: #2C3E50;
        margin: 10px 0px;
    }

    .cart-text1 {
        font-size: 14px;
        line-height: 19px;
        font-feature-settings: 'pnum'on, 'lnum'on;
        color: #2C3E50;
        margin: 2px 0px;
    }

    .tab1-text {
        font-size: 14px;
        line-height: 19px;
        font-feature-settings: 'pnum'on, 'lnum'on;
        color: #2C3E50;
        margin: 10px 0px;
    }

    .cart-item-right {
        padding: 10px 10px !important;
        right: 0;
        top: 0px !important;
        margin-right: 0 !important;
        width: 100% !important;
        border: 1px dashed grey !important;
        border-radius: 20px;
        background-color: lightgrey;
        height: 300px;
    }

    .rezervation-root {
        align-items: center;
        padding: 25px;
        margin-top: 0px;
        position: relative;
        width: 100%;
        min-width: 290px;
        height: auto;
        background: #2C3E50;
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
        border-radius: 25px;
        margin-left: 0 !important;
    }

    .sticky-top-no-transparent {
        display: flex;
        background-color: transparent;
        height: 60px;
        padding: 15px 10px;
        flex-direction: row;
        justify-content: space-between;
        transition: all 0.5s;
    }

    .sticky-top-transparent {
        display: flex;
        background-color: #2C3E50;
        height: 80px;
        padding: 0px 10px;
        flex-direction: row;
        justify-content: space-between;
        transition: all 0.5s;
    }


    .whatsapp_float {
        width: 45px;
        height: 45px;
        bottom: 20px;
        right: 20px;
        font-size: 22px;
        box-shadow: 20px 14px 35px #99999996;
    }

    .login-root-right {
        position: relative !important;
        height: 100vh;
        width: 100vw;
        background: linear-gradient(0deg, #FFFFFF 50%, rgba(28, 28, 28, 0) 80%);
    }

    .login-root-right2 {
        position: absolute;
        max-width: 273px;
        height: auto;
        right: 45%;
        width: 90%;
        top: 70%;
        transform: translate(50%, -50%);
        justify-content: space-between;
        display: flex;
        flex-direction: column;
    }

    .register-root-right2 {
        position: absolute;
        max-width: 273px;
        height: auto;
        right: 45%;
        width: 90%;
        top: 65%;
        transform: translate(50%, -50%);
        justify-content: space-between;
        display: flex;
        flex-direction: column;
    }

    .login-right-middle1 {
        background: transparent !important;
        border: none !important;
        margin: 2px 0px;
        box-shadow: none !important;
    }

    .sliderMiddleItem1 {
        font-weight: bold;
        font-size: 28px;
        text-align: center;
        letter-spacing: 0em !important;
        text-transform: uppercase;
        color: #FFFFFF;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .sliderMiddleItem2 {
        margin-top: 0px !important;
        font-weight: normal;
        font-size: 22px;
        line-height: 38px;

        text-align: center;
        letter-spacing: 0.15em !important;
        text-transform: uppercase;

        color: #FFFFFF;

        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .sliderMiddleItem3 {
        margin-top: 5px !important;
    }

    .sliderDownItem2 {
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        padding-top: 10px !important;

        color: #FFFFFF;
    }

    .login-button-transparent-white {
        background-color: transparent !important;
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25) !important;
        border-radius: 4px !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 16px !important;
        color: #FFFFFF !important;
        padding: 6px !important;
        width: 100%;
        height: auto !important;
        border-color: #FFFFFF !important;
        text-transform: uppercase !important;
    }

    .sticky-top-text-white {
        color: #FFFFFF !important;
        fill: #FFFFFF !important;
        font-size: 1.5rem;
        filter: drop-shadow(0px 0px 1px #2C3E50);
    }

    .sticky-top-text-blue {
        color: #2C3E50 !important;
        fill: #2C3E50 !important;
        font-size: 1.5rem;
    }

    .sliderDownItems {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .search-top {
        width: 100%;
        border-bottom: 2px solid rgba(44, 62, 80, 0.1);
        padding-bottom: 10px;
        margin-bottom: 10px;
    }

    .account-baslik2 {
        font-weight: 300;
        font-size: 18px;
        line-height: 28px;
        color: #2C3E50;

    }

    .account-baslik {
        font-weight: bold;
        font-size: 18px;
        line-height: 42px;
        color: #2C3E50;
        text-transform: uppercase;
    }

    .kesfet-baslik {
        font-weight: bold;
        font-size: 22px;
        line-height: 42px;
        color: #2C3E50;
        text-transform: uppercase;
    }

}

@media screen and (max-width: 1500px) {
    .card-small-text {
        font-weight: 600;
        font-size: 20px;
        color: #FFFFFF !important;

    }
}