
#components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  #components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
  }
  
  #components-layout-demo-custom-trigger .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
  }
  
  .site-layout .site-layout-background {
      overflow: auto;
      background: #fff;
  }
  .demo-option-label-item > span {
    margin-right: 6px;
  }
.admin-root{
    display: flex ;
    height: 100vh ;
}
.admin-input{
  background: transparent !important;
  margin-bottom: 10px !important;
  box-shadow: none !important;
}
.admin-input-city{
  background: transparent !important;
  margin-bottom: 10px !important;
  box-shadow: none !important;
  text-transform: uppercase;
}
.kategori-root{
  display: flex;
  flex-direction: column;
  justify-content: center !important;
}
.kategori-content-root{
  display: flex;
  flex-direction: column;
  max-width: 350px;
}
.kategori-content-root-mutual{
  display: flex;
}
.kategori-content-root-mutual-inside{
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 40px;
  max-width: 800px;
}
.activity-content-root-mutual{
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  overflow: auto;
}
.activity-content-root-mutual-inside{
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top: 40px;
  max-width: 800px;
}
.kategori-left{
  display: flex !important;
  flex-direction: column !important;
}
.kategori-right{
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-end;
}
.kategori-baslik{
  display: flex;
  align-items: center;
  font-size: large;
}

.kategori-buton{
  max-width: 150px;
  background-color: grey !important;
}
.kategori-root-top{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.kategori-root-bottom{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.ant-modal{
  display: flex;
  align-items: center;
  top: 0 !important;
  height: 100vh;
  width: 100% !important;
  max-width: 1000px !important;
}
.ant-modal-content{
  width: 100%;
}
.ant-modal-body{
  display: flex;
  justify-content: center;
}
.ant-modal-footer{
  display: none;
}
.table-root{
  width: 100%;
  margin-top: 10px;
  border: 1px solid lightgrey !important;
}
.users{
  display: flex;
  justify-content: center;
  width: 100%;
}
.ant-input-number-input{
  min-height: 40px;
}
.ant-select-selector{
  min-height: 40px;
  padding-left: 10px !important;
}
.ant-select-selector{
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-select-selection-search-input{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
}
.logout{
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-end !important;
}
.ck-content{
  min-height: 100px;
}
.ck-file-dialog-button{
display: none;
}
.ant-table-cell-fix-right{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px !important;
  width: 170px;
  padding: 0px !important;
}
.modal-root{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.ant-modal-footer{
  display: flex !important;
  justify-content: flex-end;
}
.ant-table-pagination-right{
  padding-right: 10px !important;
}
.photo-modal-root{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.rug{
  overflow: auto;
}
.rug-items{
  width: 100%;
}
.photo-edit-modal-root{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  overflow: auto;
}
.photo-edit-model-info{
  display: flex;
  justify-content: center;
  padding: 20px;
}
.photo-editted{
  border-bottom: 10px solid #0d6905;
  transition: all 0.5s;
}

.photo-editted-null{
  border-bottom: 1px solid transparent ;
  transition: 1000s;
}
.photo-edit-button{
  position: absolute;
  margin-left: 105px;
  margin-top: 10px;
}
.ant-btn-primary{
  box-shadow: none !important;
}
.admin-language{
  width: 40px !important;
  padding: 0 !important;
  text-align: center !important;
}